import { navigate } from 'gatsby'

const RedirectPage: React.FC<any> = () => {
  if (typeof window !== 'undefined') {
    navigate('/maintenance')
  }

  return null
}

export default RedirectPage
